//企业信息
<template>
  <div class="enterpriseStyle" :id="scrollBoxId">
      <!-- 财务信息 -->
      <div data-title="财务信息" class="modelBox">
        <text-title index="01" label="财务信息"
          ><template v-slot:right
            ><p class="addtext">
              若所需年份信息还没有维护，<span class="addjump" @click="jumpgys"
                >点击此处
              </span>
              快速新增
            </p></template
          ></text-title
        >
        <financial-information :tableData="inEnterpriseAllData.financeInfo" @changeConfigData="changeConfigData" ref="financeInfo"/>
        <!-- 请输入财务简析文本域 -->
        <div class="assestArea">
          <el-input
            type="textarea"
            placeholder="请输入财务简析"
            v-model="inEnterpriseAllData.examineDetails.financialInfoVO.financialAnalysisInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
            <enclosure-upload
            :fileInfos="inEnterpriseAllData.examineDetails.financialInfoVO.fileInfos"
          ></enclosure-upload>
        </div>
      </div>
      <!-- 资产信息 -->
      <div data-title="资产信息" class="modelBox">
        <text-title index="02" label="资产信息"
          ><template v-slot:left
            ><el-tooltip effect="dark" placement="top-start">
              <div slot="content">
                1.收集企业土地证、产权证等，收集担保人房屋产权调查单、银行存单扫描件、基金证券单据等，按照下表录入数据<br />
                2.简要分析资产实力，清洁资产（非抵押）规模判断分析担保人资产偿债能力。结论需撰写在报告资产情况。
              </div>
              <i class="iconfont iconwenhao"></i>
            </el-tooltip> </template
        ></text-title>
        <assets-info :tableData="inEnterpriseAllData.assetsInfo"></assets-info>
        <!-- 请汇总资产信息 -->
        <div class="assestArea">
          <el-input
            type="textarea"
            placeholder="请汇总资产信息"
            v-model="inEnterpriseAllData.examineDetails.assetInfoVO.assetInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
             <enclosure-upload
            :fileInfos="inEnterpriseAllData.examineDetails.assetInfoVO.fileInfos"
          ></enclosure-upload>
        </div>
      </div>
      <!-- 外部地产情况 -->
      <div data-title="外部地产情况" class="modelBox">
        <text-title index="03" label="外部地产情况"></text-title>
        <out-property
          :tableData="inEnterpriseAllData.outAssetsInfo"
        ></out-property>
        <!-- 请汇总外部地产合作信息 -->
        <div class="assestArea">
          <el-input
            type="textarea"
            placeholder="请汇总外部地产合作信息"
            v-model="inEnterpriseAllData.examineDetails.extEstateInfoVO.extEstateInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
             <enclosure-upload
            :fileInfos="inEnterpriseAllData.examineDetails.extEstateInfoVO.fileInfos"
          ></enclosure-upload>
        </div>
      </div>
      <!-- 负债情况 -->
      <div data-title="负债情况" class="modelBox">
        <text-title index="04" label="负债情况"
          ><template v-slot:left
            ><el-tooltip effect="dark" placement="top-start">
              <div slot="content">
                1.收集企业及担保人2周征信，按照下表录入数据（注意：要求与实控人访谈信息、企业财务报表借款类科目等交叉验证，如有不上征信的借款仍需录入表格）；
                <br />
                2.简要分析企业负债及担保情况：企业融资总规模判断分析企业是否存在过度融资情形、企业贷款月息规模判断企业付息压力、企业贷款到期日集中度判断企业偿付压力、企业或有负债（对外担保）规模判断企业偿付可能性及抗风险能力，另外，需要关注个人经营性借款规模。结论需撰写在报告负债情况；
                <br />
                3.简要分析企业及个人资信情况：是否有违约、关注、行政处罚等记录，需撰写在报告征信情况
              </div>
              <i class="iconfont iconwenhao"></i>
            </el-tooltip> </template
        ></text-title>
        <liabilities-situation
          :tableData="inEnterpriseAllData.liabilitiesInfo"
        ></liabilities-situation>
        <!-- 负债情况 -->
        <div class="module">
          <div class="head">
            <title-center :rules="false" name='负债情况' />
          </div>
          <el-input
            type="textarea"
            placeholder="多行输入"
            v-model="inEnterpriseAllData.examineDetails.debtInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
        </div>
        <!-- 短期集中到期情况（一年内） -->
        <div class="module">
          <div class="head">
            <title-center :rules="false" name='短期集中到期情况（一年内）' />
          </div>
          <el-input
            type="textarea"
            placeholder="请汇总一年内集中到期情况"
            v-model="inEnterpriseAllData.examineDetails.expiringDebtInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
        </div>
        <!-- 征信情况 -->
        <div class="module">
          <div class="head">
            <title-center :rules="false" name='征信情况' />
          </div>
          <el-input
            type="textarea"
            placeholder="多行输入"
            v-model="inEnterpriseAllData.examineDetails.creditInfoVO.creditInfo"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
          <enclosure-upload
            :fileInfos="
              inEnterpriseAllData.examineDetails.creditInfoVO.fileInfos
            "
          ></enclosure-upload>
        </div>
      </div>
      <!-- 诉讼信息 -->
      <div data-title="诉讼信息" class="modelBox">
        <text-title index="05" label="诉讼信息"></text-title>
        <litigation-info
          :tableData="inEnterpriseAllData.litigation"
          @changeLitigationInfoData="changeLitigationInfoData"
        ></litigation-info>
        <!-- 请汇总外部地产合作信息 -->
        <div class="head">
          <!-- <p>诉讼问题汇总</p> -->
          <title-center :rules="false" name='诉讼问题汇总' />
        </div>
        <div class="assestArea">
          <el-input
            type="textarea"
            placeholder="请汇总外部地产合作信息"
            v-model="inEnterpriseAllData.examineDetails.litigationInfoVO.litigationInfoComment"
            maxlength="500"
            show-word-limit
            rows="5"
          ></el-input>
             <enclosure-upload
            :fileInfos="inEnterpriseAllData.examineDetails.litigationInfoVO.fileInfos"
          ></enclosure-upload>
        </div>
    </div>
     <scroll-fool ref="scrollFool" :scrollData="scrollData" :scrollBoxId='scrollBoxId'/>
  </div>
</template>
<script>
import TextTitle from '@/components/packages/text-title/text-title.vue'
import FinancialInformation from '../components/financial-information.vue'
import AssetsInfo from '../components/assets-info.vue'
import OutProperty from '../components/out-property.vue'
import LiabilitiesSituation from '../components/liabilities-situation.vue'
import LitigationInfo from '../components/litigation-info.vue'
import EnclosureUpload from '../components/enclosure-upload.vue'
import { dueDiligenceApi } from '@/api/businessApi'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import TitleCenter from '@/pages/business/components/title-center.vue'

import Storage from '@/utils/storage'

// import FinanceInfo from '../components/finance-info.vue'
export default {
  components: {
    FinancialInformation,
    TextTitle,
    AssetsInfo,
    OutProperty,
    LiabilitiesSituation,
    LitigationInfo,
    EnclosureUpload,
    ScrollFool,
    TitleCenter
  },
  props: {
    tabsTitles: Array, // 标题错误状态
    showMain: Number, // 当前显示页面
    enterpriseAllData: Object
  },
  data () {
    return {
      showPage: 2,
      assestsSummary: '',
      savePassArr: [], // 所有提交数据校验值
      scrollBoxId: 'entBox',
      scrollData: [
        { label: '财务信息', id: 9 },
        { label: '资产信息', id: 10 },
        { label: '外部地产情况', id: 11 },
        { label: '负债情况', id: 12 },
        { label: '诉讼信息', id: 13 }
      ]
    }
  },
  computed: {
    // 外部数据传入
    inEnterpriseAllData: {
      get () {
        return this.enterpriseAllData
      },
      set (val) {
        this.$emit('changeEnterpriseAllData', val)
      }
    }
  },
  watch: {
    showMain (val) {
      if (val === 2 && !this.tabsTitles[2].pass) {
        this.saveAll('submit')
      }
    }
  },
  methods: {
    // 修改财务信息
    changeConfigData (data) {
      this.inEnterpriseAllData.financeInfo = data
    },
    // 修改诉讼信息
    changeLitigationInfoData (data) {
      this.inEnterpriseAllData.litigation = data
    },
    // changeAssetInfoData (data) {
    //   this.inEnterpriseAllData.litigation.push(data)
    // },
    // 校验所有数据
    validAllData () {
      this.savePassArr = []
      // this.validFinanceInfo()
      this.validAsset()
      this.validOutAssetsInfo()
      this.validLiabilities()
      const tip = this.savePassArr.find(item => item.isPass === false)
      console.log(tip, '发现的节点')
      if (tip) {
        if (this.showMain === this.showPage) {
          this.warning(tip.warningTip)
          this.$refs.scrollFool.touchactive(tip.module)
        }
      }
      // console.log(tip, 'xxxx')
      return tip
    },
    // 财务信息校验
    validFinanceInfo () {
      console.log(this.inEnterpriseAllData.financeInfo, '财务信息')
      if (this.inEnterpriseAllData.financeInfo.length > 0) {
        this.savePassArr.push({ isPass: false, warningTip: '完善财务信息', module: 0 })
        this.$emit('errMsg', this.showPage, false)
      }
    },
    // 资产信息校验
    validAsset () {
      let pass = true
      if (this.inEnterpriseAllData.assetsInfo.length > 0) {
        this.inEnterpriseAllData.assetsInfo.forEach(item => {
          if (!item.assetType || !item.owner || !item.address || !item.appraisalPrice || !item.loanBalance || !item.isController) {
            pass = false
          }
        })
      } else {
        pass = true
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '完善资产信息', module: 1 })
        this.$emit('errMsg', this.showPage, false)
      }
    },
    // 外部地产校验
    validOutAssetsInfo () {
      let pass = true
      if (this.inEnterpriseAllData.outAssetsInfo.length > 0) {
        this.inEnterpriseAllData.outAssetsInfo.forEach(item => {
          if (!item.name || !item.constructionProjectsNumber || !item.totalContractAmount || !item.totalReceivablesSettlements || !item.businessProportion || !item.paymentType || !item.paymentCycle) {
            pass = false
          }
        })
      } else {
        pass = true
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '完善外部地产情况', module: 2 })
        this.$emit('errMsg', this.showPage, false)
      }
    },
    // 负债情况
    validLiabilities () {
      let pass = true
      if (this.inEnterpriseAllData.liabilitiesInfo.length > 0) {
        this.inEnterpriseAllData.liabilitiesInfo.forEach(item => {
          if (!item.borrower || !item.contractAmount || !item.loanBalance || !item.expiryDate || !item.isController) {
            pass = false
          }
        })
      } else {
        pass = true
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '完善负债情况', module: 3 })
        this.$emit('errMsg', this.showPage, false)
      }
    },
    // 诉讼信息校验
    validLitigationInfo () {
      let pass = true
      this.inEnterpriseAllData.litigation.forEach(item => {
        if (!item.caseNumber) {
          pass = false
        }
      })
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '完善企业诉讼信息', module: 4 })
      }
    },
    getAllFn (type) {
      this.savefinInfo(type)
      this.saveAssetInfo(type)
      this.saveoutAssetsInfo(type)
      this.saveoutLiabilitiesInfo(type)
      this.saveoutLitigationInfo(type)
    },
    // 提交&保存所有数据
    saveAll (type) {
      if (type === 'submit') {
        if (!this.validAllData()) {
          this.getAllFn(type)
          this.$emit('errMsg', this.showPage, true)
        }
      } else if (type === 'save') {
        this.getAllFn(type)
      } else if (type === 'generate') {
        if (!this.validAllData()) {
          this.getAllFn(type)
          this.$emit('errMsg', this.showPage, true)
        }
      }
    },
    // 提交&保存财务信息
    savefinInfo (type) {
      this.inEnterpriseAllData.financeInfo.enterpriseId = Storage.getLocal('contractInfo').gysId
      const data = { businessId: this.$route.query.businessId, data: this.inEnterpriseAllData.financeInfo, enterpriseId: Storage.getLocal('contractInfo').gysId }
      if (type === 'save' || type === 'generate') {
        dueDiligenceApi.financialanalysisBatchSave(data).then(res => {})
      } else if (type === 'submit') {
        dueDiligenceApi.financialanalysisBatchSubmit(data).then(res => {})
      }
    },
    // 提交&保存资产数据
    async saveAssetInfo (type) {
      this.inEnterpriseAllData.assetsInfo.enterpriseId = Storage.getLocal('contractInfo').gysId
      const data = { businessId: this.$route.query.businessId, data: this.inEnterpriseAllData.assetsInfo, enterpriseId: Storage.getLocal('contractInfo').gysId }
      if (type === 'save' || type === 'generate') {
        dueDiligenceApi.assetinfoBatchSave(data)
      } else if (type === 'submit') {
        dueDiligenceApi.assetinfoBatchSubmit(data).then(res => {})
      }
    },
    // 提交&保存外部地产数据
    async saveoutAssetsInfo (type) {
      this.inEnterpriseAllData.outAssetsInfo.enterpriseId = Storage.getLocal('contractInfo').gysId
      const data = { businessId: this.$route.query.businessId, data: this.inEnterpriseAllData.outAssetsInfo, enterpriseId: Storage.getLocal('contractInfo').gysId }
      if (type === 'save' || type === 'generate') {
        dueDiligenceApi.extestateinfoBatchSave(data)
      } else if (type === 'submit') {
        dueDiligenceApi.extestateinfoBatchSubmit(data).then(res => {})
      }
    },
    // 提交&保存 负债信息
    async saveoutLiabilitiesInfo (type) {
      this.inEnterpriseAllData.liabilitiesInfo.enterpriseId = Storage.getLocal('contractInfo').gysId
      const data = { businessId: this.$route.query.businessId, data: this.inEnterpriseAllData.liabilitiesInfo, enterpriseId: Storage.getLocal('contractInfo').gysId }
      if (type === 'save' || type === 'generate') {
        dueDiligenceApi.debtinfoBatchSave(data)
      } else if (type === 'submit') {
        dueDiligenceApi.debtinfoBatchSubmit(data).then(res => {})
      }
    },
    // 提交&保存 企业诉讼信息
    async saveoutLitigationInfo (type) {
      this.inEnterpriseAllData.litigation.enterpriseId = Storage.getLocal('contractInfo').gysId
      const data = { businessId: this.$route.query.businessId, data: this.inEnterpriseAllData.litigation, enterpriseId: Storage.getLocal('contractInfo').gysId }
      if (type === 'save' || type === 'generate') {
        dueDiligenceApi.litigationinfoBatchSave(data).then(res => {})
      } else if (type === 'submit') { // ---------------------------------可能不校验
        dueDiligenceApi.litigationinfoBatchSubmit(data).then(res => {})
      }
    },
    // 弹出新增
    jumpgys () {
      this.$refs.financeInfo.showAdd()
    }
  }
}
</script>
<style lang='scss' scoped>
.enterpriseStyle{
  height: 100%;
  overflow-y: auto;
}
.module{
  margin-top: 20px;
  .head {
    padding:0 8px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .el-textarea{
    padding: 10px;
    width: calc(100% - 20px);
  }
}
.assestArea{
  .el-textarea{
    padding: 10px;
    width: calc(100% - 20px);
  }
}
.iconwenhao {
  font-size: 22px;
  color: #2862E7;
  padding-left: 10px;
  line-height: 30px;
  cursor: pointer;
}
.addtext {
  color: rgba(255, 37, 37, 100);
  font-size: 14px;
  margin-right: 20px;
  .addjump {
    color: #2862E7;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
